<template>
  <div class="MultiBuyCTA type-sm-medium pl-12">
    <button
      v-if="nextPercent && showCTA && needToAdd > 0"
      class="btn btn--secondary btn--md !pl-12 h-[34px] leading-single"
      :class="buttonClass"
      @click="$emit('updateQuantity', nextPercent.limit)"
    >
      <img
        src="/icons/plus.svg"
        class="inline-block w-16 mr-8"
        alt="+"
      >
      <div class="relative top-2">
        {{ $t('buyMore.nextCta.amount', { num: needToAdd }) }}
        <span class="text-criticalDark"> {{ $t('buyMore.nextCta.percent', { percent: nextPercent.gainedAmount }) }}</span>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { CartApiItem } from '~/constants/types/norce';
import useMultiDiscount from '~/composeables/useMultiDiscount';

const props = defineProps<{
  item: CartApiItem,
  buttonClass?: string,
}>();

defineEmits<{
  (e: 'updateQuantity', quantityInput: number): void;
}>();

const { nextPercent } = useMultiDiscount(props);

/**
 * Only show if half way there
 */
const showCTA = computed(()=> {
  if (!nextPercent.value) {
    return false;
  }
  return props.item.quantity / nextPercent.value.limit >= .5;
});

const needToAdd = computed(()=> {
  if (nextPercent.value) {
    return nextPercent.value.limit - props.item.quantity;
  }
  return 0;
});

</script>

<style scoped lang="postcss">
</style>
